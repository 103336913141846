import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { LoginComponent } from './features/login/login.component';
import { MainComponent } from './features/main/main.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    canActivate: [ AuthGuard ],
    component: MainComponent,
    children: [
      {
        path: 'investments',
        loadChildren: () => import('./features/investments/investments.module').then(m => m.InvestmentsModule),
      },
      { path: 'iliad', loadChildren: () => import('./features/iliad/iliad.module').then(m => m.IliadModule) },
      { path: 'podcasts', loadChildren: () => import('./features/podcasts/podcasts.module').then(m => m.PodcastsModule) },
    ]
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
