import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { HEADER_ROUTES } from '../../../../store/app';
import { AuthService } from '../../auth/auth.service';
import IUser from '../../models/user.model';

@Component({
  selector: 'ts-header',
  template: `
    <header class="header header-6">
      <div class="branding">
        <a routerLink="/" class="nav-link">
          <clr-icon shape="tasks"></clr-icon>
          <span class="title">Task Scheduler</span>
        </a>
      </div>
      <div class="header-nav">
        <a *ngFor="let item of headerRoutes"
           [routerLink]="item.href"
           routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}"
           class="nav-link nav-text">
          {{item.title}}
        </a>
      </div>
      <div class="header-actions">
        <clr-dropdown *ngIf="user">
          <button class="nav-text" clrDropdownTrigger aria-label="open user profile">
            <!-- TODO avatar -->
            {{fullName}}
            <clr-icon shape="caret down"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <h4 class="dropdown-header">{{fullName}}</h4>
            <div class="dropdown-item disabled" *ngIf="fullName != user.username">
              @{{user.username}}
            </div>
            <div class="dropdown-item disabled">
              Since
              <ts-time-ago [date]="user.dateJoined"></ts-time-ago>
            </div>
            <div class="dropdown-divider" role="separator"></div>
            <a routerLink="logout" clrDropdownItem (click)="doLogout()">
              Log out
            </a>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
    </header>
  `,
  styles: []
})
export class HeaderComponent implements OnInit {
  headerRoutes = HEADER_ROUTES;
  user?: IUser = null;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe(user => this.user = user);
  }

  doLogout(): Observable<boolean> {
    this.authService.logout();
    return from(this.router.navigate(['/login']));
  }

  get fullName(): string {
    const { username, firstName, lastName } = this.user;
    return firstName || lastName ? `${firstName} ${lastName}` : username;
  }
}
