import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

export function formatReadable(date: Date): string {
  return format(date, 'PPPPpp');
}

export function formatTimeAgo(date: Date): string {
    return formatDistanceToNowStrict(date, { addSuffix: true });
  }
