import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import parseJSON from 'date-fns/parseJSON';
import { BehaviorSubject, Observable, of, throwError, iif } from 'rxjs';
import { catchError, map, switchMap, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import IUser from '../models/user.model';
import { SettingsService } from '../services/settings.service';

export const TOKEN_STORAGE_KEY = 'auth_token';

export const LOGIN_URL = `${environment.apiUrl}/api/auth/`;
export const CURRENT_USER_URL = `${environment.apiUrl}/api/users/me/`;

export const UNAUTHENTICATED_URLS = [
  LOGIN_URL
];

@Injectable()
export class AuthService {
  private _token$ = new BehaviorSubject<string>(null);
  private _user$: Observable<IUser>;

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) {
    const userLoader$ = this.http.get<any>(CURRENT_USER_URL).pipe(
      map(u => {
        const user: IUser = {
          id: u.id,
          username: u.username,
          firstName: u.first_name,
          lastName: u.last_name,
          isSuperUser: u.is_super_user,
          email: u.email,
          isStaff: u.is_staff,
          isActive: u.is_active,
          dateJoined: parseJSON(u.date_joined),
          profilePicture: u.profile_picture,
        };
        return user;
      })
    );

    this._user$ = this._token$.pipe(
      switchMap(token => iif(() => !token, of(null as IUser), userLoader$)),
      shareReplay(1),
    );

    // Restore token from localStorage
    const token = this.loadToken();
    this.setToken(token);
  }

  /**
   * Starts the login procedure
   * @param username task_scheduler username
   * @param password task_scheduler password
   */
  login(username: string, password: string): Observable<boolean> {
    return this.http
      .post<any>(LOGIN_URL, { username, password })
      .pipe(
        switchMap(r => {
          if (r.token) {
            this.setToken(r.token);
            return of(true);
          }
          return of(false);
        }),
        catchError(val => {
          this.setToken(null);

          let message = 'Login failed';
          if (val.error?.non_field_errors?.length > 0) {
            message = val.error.non_field_errors[0];
          }
          return throwError(message);
        })
      );
  }

  /**
   * Starts the logout procedure
   */
  logout(): Observable<boolean> {
    this.setToken(null);

    return of(true);
  }

  /**
   * Current authentication token
   */
  get token$(): Observable<string> {
    return this._token$.asObservable();
  }

  /**
   * Current authenticated user
   */
  get user$(): Observable<IUser | null> {
    return this._user$;
  }

  /**
   * Check if user is logged
   */
  get isLoggedIn$(): Observable<boolean> {
    return this.token$.pipe(
      map(token => !!token)
    );
  }

  private loadToken(): string | null {
    try {
      return this.settings.get(TOKEN_STORAGE_KEY);
    } catch {
      return null;
    }
  }

  private setToken(token: string): void {
    if (!token) {
      token = null;
    }
    this.settings.set(TOKEN_STORAGE_KEY, token);
    this._token$.next(token);
  }
}
