import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClrAlertModule, ClrDropdownModule, ClrIconModule, ClrVerticalNavModule } from '@clr/angular';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { HeaderComponent } from './components/header/header.component';
import { TimeAgoComponent } from './components/time-ago/time-ago.component';
import { ToastComponent } from './components/toast/toast.component';
import { ThemeDirective } from './directives/theme.directive';
import { HttpResponseInterceptor } from './interceptors/http-response.interceptor';

@NgModule({
  declarations: [
    HeaderComponent,
    ThemeDirective,
    ToastComponent,
    TimeAgoComponent,
  ],
  exports: [
    HeaderComponent,
    ThemeDirective,
    TimeAgoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ClrIconModule,
    ClrDropdownModule,
    ClrVerticalNavModule,
    ClrAlertModule,
    ToastrModule.forRoot({
      closeButton: true,
      toastComponent: ToastComponent,
      toastClass: '',
    }),
  ],
  entryComponents: [
    ToastComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
    AuthService,
  ]
})
export class CoreModule {}
