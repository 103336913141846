import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ts-root',
  template: `
    <router-outlet tsTheme></router-outlet>
  `,
  styles: []
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
  }
}
