import { Component } from '@angular/core';
import { isMenuLink, isMenuLinkWithChildren, SIDEBAR_ROUTES } from '../../../store/app';
import { MenuItem, MenuLink, MenuLinkCollection, MenuLinkWithChildren } from '../../core/models/menu.model';

@Component({
  selector: 'ts-main',
  template: `
    <div class="main-container">
      <!--      <div class="alert alert-app-level">-->
      <!--        App level alert-->
      <!--      </div>-->
      <ts-header></ts-header>
      <!--      <nav class="subnav">-->
      <!--        Subnav-->
      <!--      </nav>-->
      <div class="content-container">
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" class="nav-trigger--bottom">
          <ng-container *ngFor="let item of sidebarRoutes">

            <a *ngIf="isMenuLink(item)"
               class="nav-link"
               [routerLink]="item.href"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}"
               clrVerticalNavLink>
              <clr-icon [attr.shape]="item.icon" clrVerticalNavIcon></clr-icon>
              {{item.title}}
            </a>

            <clr-vertical-nav-group *ngIf="isMenuLinkWithChildren(item)"
                                    routerLinkActive="active">
              <a clrVerticalNavLink
                 [routerLink]="item.baseHref"
                 routerLinkActive="active">
                <clr-icon [attr.shape]="item.icon" clrVerticalNavIcon></clr-icon>
                {{item.title}}
              </a>

              <clr-vertical-nav-group-children *clrIfExpanded>
                <a *ngFor="let child of item.children"
                   clrVerticalNavLink
                   [routerLink]="child.href"
                   routerLinkActive="active"
                   [routerLinkActiveOptions]="{exact: true}">
                  {{child.title}}
                </a>
              </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>

          </ng-container>
        </clr-vertical-nav>
        <div class="content-area" clrFocusOnViewInit>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class MainComponent {
  sidebarRoutes: MenuLinkCollection = SIDEBAR_ROUTES;

  constructor() {}

  isMenuLink(sidebarLink: MenuItem): sidebarLink is MenuLink {
    return isMenuLink(sidebarLink);
  }

  isMenuLinkWithChildren(sidebarLink: MenuItem): sidebarLink is MenuLinkWithChildren {
    return isMenuLinkWithChildren(sidebarLink);
  }
}
