import { Injectable } from '@angular/core';

export type TransformFn<T> = (val: string | number | object | Array<any>) => T;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  public get<T>(key: string, transform?: TransformFn<T>): string | T | null {
    let item = this.storage.getItem(key);
    if (!item) {
      return null;
    }

    item = JSON.parse(item);
    if (!item) {
      return null;
    }

    if (transform) {
      return transform(item);
    }

    return item;
  }

  public getOrDefault<T>(key: string, defaultValue: T, transform?: TransformFn<T>, set: boolean = true): string | T | null {
    const value = this.get(key, transform);
    if (!value) {
      if (set) {
        this.set(key, defaultValue);
      }
      return defaultValue;
    }
    return value;
  }

  public set(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }
}
