import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { switchMap, mapTo } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService, UNAUTHENTICATED_URLS } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only on API URL
    if (!req.url.startsWith(environment.apiUrl)) {
      return next.handle(req);
    }

    // Do not add token on unauthenticated urls (ex: login)
    if (UNAUTHENTICATED_URLS.find(u => req.url.startsWith(u))) {
      return next.handle(req);
    }

    return this.authService.token$
      .pipe(
        switchMap(token => {
          if (!token) {
            // Not logged in
            return from(this.router.navigate(['login'])).pipe(
              mapTo(undefined),
            );
          }

          const duplicate = req.clone({
            headers: req.headers.set('Authorization', `Token ${token}`)
          });

          return next.handle(duplicate);
        })
      );
  }
}
