import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ts-toast',
  template: `
    <!-- [clrAlertClosable]="options.closeButton" (clrAlertClosedChange)="onRemove()"-->
    <clr-alert [clrAlertType]="alertType" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">
          <span class="alert-title" *ngIf="title">{{ title }}</span>
          {{ message }}
        </span>
      </clr-alert-item>
    </clr-alert>
  `,
  styles: [ `
    .alert-title {
      display: block;
      font-weight: bold;
    }
  ` ],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
})
export class ToastComponent extends Toast {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  onRemove(): void {
    console.log('toast remove');
    this.remove();
  }

  get alertType(): string {
    switch (this.toastPackage.toastType) {
      case 'toast-error':
        return 'danger';
      case 'toast-warning':
        return 'warning';
      case 'toast-info':
        return 'info';
      case 'toast-success':
        return 'success';
    }
  }
}
