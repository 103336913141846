import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'ts-login',
  template: `
    <div class="login-wrapper">
      <form class="login" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <section class="title">
          <h3 class="welcome">Welcome to</h3>
          Task Scheduler
          <h5 class="hint">Use your Company ID to sign in or create one now</h5>
        </section>
        <div class="login-group">
          <clr-input-container>
            <label class="clr-sr-only">Username</label>
            <input type="text"
                   name="username"
                   formControlName="username"
                   clrInput
                   placeholder="Username"
                   autocomplete="username"/>
          </clr-input-container>
          <clr-password-container>
            <label class="clr-sr-only">Password</label>
            <input type="password"
                   name="password"
                   formControlName="password"
                   clrPassword
                   placeholder="Password"
                   autocomplete="current-password"/>
          </clr-password-container>
          <div class="error active" *ngIf="errorMessage">
            {{errorMessage}}
          </div>
          <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">
            NEXT
          </button>
        </div>
      </form>
    </div>
  `,
  styles: []
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = this.fb.group({
    username: [ '', Validators.required ],
    password: [ '', Validators.required ],
  });

  errorMessage?: string;
  private isLoggedInSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.isLoggedInSub = this.authService.isLoggedIn$.subscribe(async logged => {
      if (logged) {
        await this.router.navigate([ '' ]);
      }
    });
  }

  ngOnDestroy(): void {
    this.isLoggedInSub.unsubscribe();
  }

  onSubmit(): void {
    const { username, password } = this.loginForm.value;

    this.authService.login(username, password)
      .pipe(
        catchError(error => {
          this.errorMessage = error;
          return of(false);
        })
      )
      .subscribe();
  }
}
