import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, Directive, Inject, OnDestroy, OnInit } from '@angular/core';

enum Theme {
  Light = 'clr-ui-light.css',
  Dark = 'clr-ui-dark.css',
  Auto = 'auto',
}

const THEME_LINK_ID = 'theme-stylesheet';

@Directive({
  selector: '[tsTheme]',
})
export class ThemeDirective implements OnInit, AfterViewChecked, OnDestroy {
  private currentTheme: Theme = Theme.Auto;
  private previousTheme: Theme = undefined;
  private isDarkQuery: MediaQueryList;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.isDarkQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.isDarkQuery.addEventListener('change', this.onIsDarkQueryChange);

    this.currentTheme = this.isDarkQuery.matches ? Theme.Dark : Theme.Light;
    this.loadStyle();
  }

  ngAfterViewChecked(): void {
    this.loadStyle();
  }

  ngOnDestroy(): void {
    this.isDarkQuery.removeEventListener('change', this.onIsDarkQueryChange);
  }

  onIsDarkQueryChange = (event: MediaQueryListEvent) => {
    this.currentTheme = event.matches ? Theme.Dark : Theme.Light;
    this.loadStyle();
  }

  loadStyle(): void {
    // avoid theme change burst
    if (this.currentTheme === this.previousTheme) {
      return;
    }
    this.previousTheme = this.currentTheme;

    // load style
    const head = this.document.head;
    const themeLink = this.document.getElementById(THEME_LINK_ID) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = this.styleSheetName;
    } else {
      const style = this.document.createElement('link');
      style.id = THEME_LINK_ID;
      style.rel = 'stylesheet';
      style.href = this.styleSheetName;

      // insert first after <head> to allow overrides
      head.insertBefore(style, head.firstChild);
    }
  }

  get styleSheetName(): string {
    if (this.currentTheme === Theme.Auto) {
      return Theme.Light;
    }
    return this.currentTheme;
  }
}
