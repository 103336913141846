import { HeaderMenuLinkCollection, MenuItem, MenuLink, MenuLinkCollection, MenuLinkWithChildren } from '../app/core/models/menu.model';

export function isMenuLink(sidebarLink: MenuItem): sidebarLink is MenuLink {
  const single = sidebarLink as MenuLink;
  return single.href !== undefined;
}

export function isMenuLinkWithChildren(sidebarLink: MenuItem): sidebarLink is MenuLinkWithChildren {
  const single = sidebarLink as MenuLinkWithChildren;
  return single.children !== undefined;
}

export const SIDEBAR_ROUTES: MenuLinkCollection = [
  {
    id: 'main',
    title: 'Dashboard',
    icon: 'dashboard',
    href: '/',
  },
  {
    id: 'investments',
    title: 'Investments',
    icon: 'line-chart',
    baseHref: '/investments',
    children: [
      {
        id: 'overview',
        title: 'Overview',
        href: '/investments',
      },
      {
        id: 'bondora',
        title: 'Bondora',
        href: '/investments/bondora',
      },
      {
        id: 'mintos',
        title: 'Mintos',
        href: '/investments/mintos',
      },
    ],
  },
  {
    id: 'iliad',
    title: 'iliad',
    icon: 'phone-handset',
    href: '/iliad',
  },
  {
    id: 'podcasts',
    title: 'Podcasts',
    icon: 'microphone',
    href: '/podcasts',
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: 'cog',
    href: '/settings',
  },
];

export const HEADER_ROUTES: HeaderMenuLinkCollection = [
  {
    id: 'main',
    title: 'Home',
    href: '/',
  },
  {
    id: 'investment__overview',
    title: 'Investments',
    href: '/investments',
  },
];
