import { Component, Input } from '@angular/core';
import formatISO from 'date-fns/formatISO';
import { formatReadable, formatTimeAgo } from '../../utils/dates';

@Component({
  selector: 'ts-time-ago',
  template: `
    <time [dateTime]="dateISO" [title]="dateReadable">
      {{dateTimeAgo}}
    </time>
  `,
  styles: []
})
export class TimeAgoComponent {
  @Input() date: Date;

  get dateISO(): string {
    return formatISO(this.date);
  }

  get dateTimeAgo(): string {
    return formatTimeAgo(this.date);
  }

  get dateReadable(): string {
    return formatReadable(this.date);
  }
}
